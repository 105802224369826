import React, { useEffect, useState } from "react";
import { Menu, Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { getPermissionsData, getUserData } from "../../../../store/auth";
import { useSelector } from "react-redux";
import { removeDuplicatesFromArray } from "../../../../utils/removeDuplicates";

const { Sider } = Layout;

const LayoutSider = ({ hasAccess }) => {
  let location = useLocation();
  const navigate = useNavigate();
  const userData = useSelector(getUserData);
  const [selectedItem, setSelectedItem] = useState("1");
  const permissions = useSelector(getPermissionsData);
  const [current] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );

  const redirect = (route) => navigate(route);

  const renderItem = (item) => {
    if (item.key.startsWith("sub")) {
      return (
        <Menu.SubMenu key={item.key} title={item.label}>
          {item.children.map((child) => (
            <Menu.Item key={child.key} onClick={() => redirect(child.redirect)}>
              {child.label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item key={item.key} onClick={() => redirect(item.redirect)}>
        {item.label}
      </Menu.Item>
    );
  };

  const getItem = (label, key, redirect, children, group = "") => {
    return {
      key,
      redirect,
      children,
      label,
      group,
    };
  };

  const handleClick = (e) => {
    localStorage.setItem("dashboardSelector", e.key);
  };

  useEffect(() => {
    const localStorageSelector = localStorage.getItem("dashboardSelector");

    if (localStorageSelector && current !== "/dashboard") {
      setSelectedItem(localStorageSelector);
    }
    renderMenuItems();
  }, [userData]);

  const renderMenuItems = () => {
    let count = 1;
    let adminItems = [];
    let menuItems = [];
  
    for (let permission of userData?.permissions || []) {
      const foundPermission = permissions?.find((p) => p.id === permission);
      const description = foundPermission?.description.toLowerCase();
  
      if (
        description === "usuarios" ||
        description === "crear / editar usuarios" ||
        description === "ver / listar usuarios" ||
        description === "desactivar usuarios"
      ) {
        adminItems.push(getItem("Usuarios", `${++count}`, "/usuarios"));
      } else if (description === "roles" || description === "empresas") {
        adminItems.push(
          getItem(
            foundPermission.description,
            `${++count}`,
            `/${description}`
          )
        );
      } else {
        const itemName = capitalizeFirstLetter(description);
        const itemPath =
          description === "vehículos"
            ? "/vehiculos"
            : `/${description}`;
            
          menuItems.push(getItem(itemName, `${++count}`, itemPath));
        }
    }
  
    menuItems = [
      getItem("Dashboard", "1", "/dashboard"),
      getItem(
        "Administrador",
        "sub1",
        "",
        removeDuplicatesFromArray(adminItems),
        "group"
      ),
      ...menuItems
    ];

    if (adminItems.length === 0) menuItems.splice(1, 1);
    return userData?.type === "CLIENT" ?  menuItems.filter(item => item.redirect !== "/administrador" && item.label !== "Servicios") :  menuItems.filter(item => item.redirect !== "/administrador")
    
  };
  
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      width={250}
      className="sidebar site-layout-background"
    >
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultOpenKeys={["sub1"]}
        forceSubMenuRender={true}
        onClick={handleClick}
        selectedKeys={[selectedItem]}
      >
        {hasAccess &&
          userData &&
          renderMenuItems()?.map((item) => renderItem(item))}
      </Menu>
    </Sider>
  );
};

export default LayoutSider;
