import { Col, Layout, Row } from "antd";
import React from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import Button from "../../../Button";
import Header from "../Header";
import "./index.less";
import siniestroLogo from "../../../../assets/img/Ico_btn_portalsiniestro.svg";
import { useSelector } from "react-redux";
import { getHomeHeaderContent } from "../../../../store/strapi/gamaleasing";
const Banner = ({
  title = "",
  subtitle = "",
  button = false,
  height = 100,
  urlImage = "",
  titleAlign = "center",
  fontSize,
}) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const headerContent = useSelector(getHomeHeaderContent);

  return (
    <Layout>
      {window.location.pathname === "/declarar-siniestro" ? null : (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div
            style={{
              marginRight: biggerThan900 ? "" : "5px",
              width: biggerThan900 ? "270px" : "100px",
              paddingTop: "10px",
              paddingBottom: "10px",
              backgroundColor: "white",
              position: "fixed",
              zIndex: "999",
              marginTop: biggerThan900 ? "200px" : "120px",
              borderTopLeftRadius: biggerThan900 ? "40px" : "20px",
              borderBottomLeftRadius: biggerThan900 ? "40px" : "20px",
              borderTopRightRadius: biggerThan900 ? 0 : "20px",
              borderBottomRightRadius: biggerThan900 ? 0 : "20px",
              cursor: "pointer",
            }}
          >
            <Row align="middle" justify="center">
              <Col span={biggerThan900 ? 4 : 10}>
                <img
                  src={siniestroLogo}
                  style={{
                    fontSize: "40px",
                    color: "#FF3200",
                  }}
                />
              </Col>

              <Col
                span={biggerThan900 ? 15 : 18}
                style={{
                  color: "#FF3200",
                  fontSize: biggerThan900 ? "17px" : "10px",
                  margin: 0,
                  fontWeight: "700",
                  lineHeight: "20px",
                  marginLeft: "5px",
                  textAlign: biggerThan900 ? "" : "center",
                }}
              >
                <a href="https://pss.gamamobility.cl/home">
                  Declaracion de Robo y siniestros
                </a>
              </Col>
            </Row>
          </div>
        </div>
      )}

      <section
        className="home-banner-container"
        id="header"
        style={{
          height: `${height}vh`,
        }}
      >
        <Header urlImage={urlImage} />
        <div className="banner-title-container">
          <h1
            style={{
              textAlign: titleAlign,
              paddingLeft:
                titleAlign === "left" && biggerThan900 ? "8.125rem" : "0",
            }}
          >
            {title}
          </h1>
          <h3
            style={{
              textAlign: titleAlign,
              lineHeight: "35px",
              fontSize: fontSize,
              fontWeight: "400",

              paddingLeft:
                titleAlign === "left" && biggerThan900 ? "8.125rem" : "0",
            }}
          >
            {subtitle}
          </h3>
          {button && (
            <Link to="/contact">
              <Button style={{ width: "11rem", marginTop: "1.5rem" }}>
                {headerContent
                  ? headerContent?.bannerButton.Label
                  : "Nuevo Arriendo"}
              </Button>
            </Link>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Banner;
