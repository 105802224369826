import { createSlice } from "@reduxjs/toolkit";
import { attachAccess, authUser, getAuthProfile, refreshAuth, getPermissions } from "./thunks";
import { STATUS } from "../../utils/status";
import {
  getToken,
  getTokenPayload,
  removeToken,
  setToken,
} from "../../utils/jwt";
import { removeFilters } from "../../utils/filters";

const initialState = {
  accessToken: null,
  hasAccess: false,
  user: {
    name: "",
    rut: "",
    lastname: "",
    activeCustomer: null,
    accessToCustomers: [],
    passwordUpdate: null,
    defaultCompany: '',
    type: '',
    permissions: [],
    accesses: [],
  },
  permissions: [],
  status: STATUS.IDLE,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadToken: (state) => {
      const token = getToken();
      if (!token) {
        return;
      }
      const { accessId } = getTokenPayload();
      state.hasAccess = !!accessId;
      state.accessToken = token;
    },
    cleanError: (state) => {
      state.status = initialState.status;
      state.error = initialState.error;
    },
    signOut: (state) => {
      removeToken();
      removeFilters();
      state.accessToken = initialState.accessToken;
      state.hasAccess = initialState.hasAccess;
      state.status = initialState.status;
      state.user = initialState.user;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authUser.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(authUser.fulfilled, (state, action) => {
        const { accessToken } = action.payload;
        state.status = STATUS.SUCCESS;
        state.accessToken = accessToken;
      })
      .addCase(authUser.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(attachAccess.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(attachAccess.fulfilled, (state, action) => {
        const { accessToken } = action.payload;

        setToken(accessToken);
        state.status = STATUS.SUCCESS;
        state.accessToken = accessToken;
        state.hasAccess = true;
      })
      .addCase(attachAccess.rejected, (state, action) => {
        state.accessToken = null;
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getAuthProfile.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getAuthProfile.fulfilled, (state, action) => {
        const { 
          id, 
          rut, 
          firstName, 
          lastName, 
          email, 
          customerAccesses, 
          passwordUpdate, 
          defaultCompany, 
          type, 
          permissions, 
          accesses,
          profileImageUrl 
        } = action.payload;
        state.status = STATUS.SUCCESS;
        state.user = {
          id: id,
          rut: rut,
          name: firstName,
          lastname: lastName,
          email: email,
          accessToCustomers: customerAccesses,
          passwordUpdate: passwordUpdate,
          defaultCompany: defaultCompany,
          type: type,
          profileImageUrl,
          permissions: permissions,
          accesses: accesses
        };
      })
      .addCase(getAuthProfile.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(refreshAuth.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(refreshAuth.fulfilled, (state, action) => {
        const token  = action.payload;
        state.status = STATUS.SUCCESS;
        setToken(token);
      })
      .addCase(refreshAuth.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getPermissions.pending,(state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getPermissions.fulfilled,(state, action)=> {
        const permissions = action.payload;
        state.permissions = [
          ...permissions[0].children,
          ...permissions[0].children[0].children,
          ...permissions
        ];
      })
      .addCase(getPermissions.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
  },
});

export const getHasAccess = (state) => state.auth.hasAccess;
export const getUserData = (state) => state.auth.user;
export const getPermissionsData = (state) => state.auth.permissions;
export const getAccessToken = (state) => state.auth.accessToken;
export const getStatus = (state) => state.auth.status;
export const error = (state) => state.auth.error;

export const { authenticate, cleanError, signOut, loadToken } =
  authSlice.actions;

export default authSlice.reducer;
