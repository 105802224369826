import { CloseOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Typography, Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import { format, validate } from "rut.js";
import { decodeClientHash } from "../../../../requests/auth";
import Button from "../../../Button";
import AuthModal from "./AuthModal";
import "./index.less";
import RecoveryPasswordForm from "./RecoveryPassword";
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_AUTH_API_URL } from "../../../../requests/url";
import microsoftLogo from "../../../../assets/img/Microsoft_logo.svg.png";
const { Text } = Typography;

const AuthForm = ({ open, close, onFinish, form, loading }) => {
  const root = document.querySelector("body");
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [pageForm, setPageForm] = useState(1);
  const localHash = localStorage.getItem("local.pass");
  const reRef = useRef();

  const payload = {
    rut: localHash ? localStorage.getItem("local.usuario") : undefined,
    password: undefined,
    remember: localHash ? true : false,
  };

  let formInitialValues = payload;

  const onClose = () => {
    setPageForm(1);
  };

  const onSubmit = () => {
    form.submit();
  };

  const onFormFinish = async (values) => {
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    const payload = { ...values, reCaptcha: token };
    onFinish(payload);
    close();
  };

  useEffect(() => {
    async function decodeHash(hash) {
      const { data } = await decodeClientHash({ hash });
      form.setFieldsValue({
        password: data,
      });
    }

    if (open === true) {
      root.style.overflow = "hidden";
      localStorage.getItem("local.usuario") && decodeHash(localHash);

      return;
    }

    root.style.overflow = "auto";
    root.style.width = "auto";
    return () => setTimeout(setPageForm(1), 2000);
  }, [open]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" && open) {
        close();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <>
      <div
        style={{
          width: biggerThan900 ? "" : "320px",
          overflow: "hidden",
          borderRadius: biggerThan900 ? "" : "20px",
        }}
        className="container-auth-form"
      >
        {/* <div className="icons-container">
          <div
            className="arrow"
            style={{ transform: biggerThan900 ? "translateX(-2rem)" : "" }}
          >
            {pageForm === 2 && (
              <ArrowLeftOutlined onClick={() => setPageForm(1)} />
            )}
          </div>
          <div className="close" style={{ transform: "translateX(2rem)" }}>
            {biggerThan900 && <CloseOutlined onClick={onClose} />}
          </div>
        </div> */}

        <div style={{ paddingTop: "1rem" }} className="contain-modal-header">
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col>
              <h3
                style={{
                  paddingBottom: "0px",
                  color: "white",
                }}
              >
                {pageForm === 1
                  ? "Sistema de Portal Cliente"
                  : "¿Olvidaste tu contraseña?"}
              </h3>
            </Col>
            <Col>
              <Link to="/">
                <CloseOutlined style={{ color: "white", cursor: "pointer" }} />
              </Link>
            </Col>
          </Row>

          <Text className="info-text" style={{ color: "white" }}>
            {pageForm === 1
              ? "Ingresa tus datos para comenzar"
              : "Ingresa tu correo y te enviaremos los pasos a seguir para reestablecer tu contraseña."}
          </Text>
        </div>
        {pageForm === 1 && (
          <>
            <Form
              name="auth"
              layout="vertical"
              form={form}
              initialValues={formInitialValues}
              onFinish={onFormFinish}
            >
              <div>
                <Form.Item
                  style={{ color: "white", paddingTop: "16px" }}
                  label="Rut"
                  name="rut"
                  rules={[
                    { required: true, message: "Ingrese el rut." },
                    {
                      validator: (_, value) => {
                        if (validate(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("El rut ingresado es inválido.");
                      },
                    },
                  ]}
                  normalize={(value) => format(value)}
                >
                  <Input style={{ borderRadius: "6px" }} placeholder="RUT" />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[
                    { required: true, message: "Ingrese la contraseña." },
                  ]}
                >
                  <Input.Password
                    style={{ borderRadius: "6px" }}
                    required
                    placeholder="Contraseña"
                    type={"password"}
                  />
                </Form.Item>
              </div>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox style={{ color: "white" }}>
                  Recordar mi usuario
                </Checkbox>
              </Form.Item>

              <Button
                type="primary"
                className="gama-button auth-button"
                onClick={() => onSubmit()}
                loading={loading}
                style={{
                  marginBottom: "15px",
                  backgroundColor: "#FF5F00",
                  border: "0px solid white",
                }}
              >
                {loading ? "Esperando" : "Iniciar Sesion"}
              </Button>

              <a
                style={{
                  marginBottom: "20px",
                  color: "white",
                  fontSize: "14px",
                }}
                onClick={() => setPageForm(2)}
              >
                ¿Olvidaste tu contraseña?
              </a>
            </Form>
            <Divider
              style={{
                width: "50px",
                color: "white",
                border: "0px solid white",
                fontSize: "16px",
              }}
            >
              o
            </Divider>
            <Row
              align="middle"
              justify="center"
              style={{ borderRadius: "1px solid gray", marginBottom: "6px" }}
            >
              <a
                href={`${BASE_AUTH_API_URL}/ms/auth`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid white",
                    width: "286px",
                  }}
                >
                  <img
                    alt="microsoft-login-logo"
                    style={{ height: "15px" }}
                    src={microsoftLogo}
                  />
                  Ingresar con Microsoft
                </Button>
              </a>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                ref={reRef}
                size="invisible"
              />
            </Row>
            <div className="footer-auth-form">
              {/* <span style={{ color: "#595959", textAlign: "center" }}>
                ¿Necesitas ayuda?
              </span> */}
              {/* <span
                style={{
                  color: "#595959",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p style={{ textAlign: "center", margin: 0, fontSize: "14px" }}>
                  Comunícate con nosotros en{" "}
                </p>
                <HashLink smooth to="#home-layout-footer" onClick={onClose}>
                  servicio al cliente
                </HashLink>
              </span> */}
            </div>
          </>
        )}
        {pageForm === 2 && <RecoveryPasswordForm onClose={onClose} />}
      </div>

      <AuthModal open={open} />
    </>
  );
};

export default AuthForm;
