import React, { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Layout, Menu, Row, Spin, Typography } from "antd";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";

import { useDispatch, useSelector } from "react-redux";
import { getUserData, getHasAccess, signOut } from "../../../store/auth";
import Notifications from "../../Notifications";
import Button from "../../Button";
import {
  exportTemplate,
  passwordChange,
  updateDefaultCompany,
} from "../../../requests/users";
import {
  CaretDownOutlined,
  DownloadOutlined,
  LoadingOutlined,
  StarFilled,
  StarOutlined,
  WindowsFilled,
} from "@ant-design/icons";

import "./index.less";
import {
  attachAccess,
  getAuthProfile,
  getPermissions,
  refreshAuth,
} from "../../../store/auth/thunks";
import { useMediaPredicate } from "react-media-hook";
import { exportVehiclesTemplate } from "../../../requests/vehicles";
import Sider from "./Sider";
import Header from "./Header";
import ChangePasswordForm from "./ChangePasswordForm";

const { Content } = Layout;
const { Text } = Typography;

const AppLayout = ({
  children,
  noContent = false,
  tabs = false,
  title,
  boton,
  backgroundImg,
  agendamiento,
}) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const hasAccess = useSelector(getHasAccess);
  const [companies, setCompanies] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const reRef = useRef();

  const data = companies.map((customer, index) => {
    return {
      label: (
        <a
          onClick={() => {
            dataLoad(customer?.id);
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#262626",
            }}
          >
            {customer.id === userData.defaultCompany ? (
              <StarFilled style={{ marginRight: ".5rem", color: "#FF3200" }} />
            ) : (
              <StarOutlined style={{ marginRight: ".5rem" }} />
            )}
            {`${customer.name} · ${customer.rut}`}
            <p
              style={{
                marginLeft: "1.4rem",
                color: "#8C8C8C",
                fontSize: "14px",
              }}
            >
              {userData.defaultCompany === customer.id && "(predeterminada)"}
            </p>
          </div>
        </a>
      ),
      key: customer.id,
    };
  });

  const antIcon = (
    <Spin
      style={{
        fontSize: 24,
      }}
      indicator={<LoadingOutlined />}
    />
  );

  const menu = (
    <Menu items={data} className="customers-list" id="customers-list" />
  );

  useEffect(() => {
    dispatch(getAuthProfile());
    dispatch(getPermissions());
  }, [dispatch]);

  useEffect(() => {
    if (userData.accessToCustomers.length) {
      const currentId = !userData.defaultCompany
        ? userData.accessToCustomers[0].id
        : userData.defaultCompany;

      onSelectCustomer(currentId);
      renderCompanies(userData);
    }
  }, [userData]);

  useEffect(() => {
    const token = localStorage.getItem("gama.token");
    if (token) setToken(token);
  }, []);

  const BASE_URL = `${process.env.REACT_APP_AGENDAMIENTO_URL}/auth/lop?token=${token}`;

  const onSelectCustomer = (customerId) => dispatch(attachAccess(customerId));
  const onSignOut = () => dispatch(signOut());

  const onSubmit = async (values) => {
    try {
      const { id } = userData;
      const data = { password: values.password };
      await passwordChange({ userId: id, data });
      Notifications.success("Terminado", "Se ha actualizado la contraseña");
      window.location.reload();
    } catch (error) {
      Notifications.error(
        "Error al cambiar contraseña",
        "Ha ocurrido un error al cambiar la contraseña"
      );
    }
  };

  const generateExcelBuffer = (data) => {
    const sheet = XLSX.utils.json_to_sheet(data);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, "Sheet1");
    const buffer = XLSX.write(book, { type: "buffer", bookType: "xlsx" });
    return buffer;
  };

  const downloadExcelFile = (buffer, fileName) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);
  };

  const downloadTemplate = async () => {
    setLoading(true);
    try {
      const { data } = await exportTemplate();
      downloadExcelFile(data, "Reporte de Solicitudes");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadVehiclesTemplate = async () => {
    setLoading(true);
    try {
      let result = [];
      const pageSize = 1000;
      const { data } = await exportVehiclesTemplate({ page: 1 });
      let totalPages = Math.trunc(data.count / pageSize) + 1;
      for (let page = 1; page <= totalPages; page++) {
        const { data } = await exportVehiclesTemplate({ page });
        result = [...result, ...data.data];
      }
      const bufferData = generateExcelBuffer(result);
      downloadExcelFile(bufferData, "Reporte de Vehiculos");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const dataLoad = async (companyId) => {
    try {
      await updateDefaultCompany(companyId);
      dispatch(getAuthProfile());
      dispatch(refreshAuth());
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanyName = (id) => {
    const company = userData.accessToCustomers.find((el) => el.id === id);

    if (company) return company.name;

    return userData.accessToCustomers[0].name;
  };

  const renderCompanies = (data) => {
    const orderData = data.accessToCustomers.reduce((acc, el) => {
      if (el.id === userData.defaultCompany) {
        return [el, ...acc];
      } else {
        return [...acc, el];
      }
    }, []);

    setCompanies(orderData);
  };

  const renderContent = () => {
    if (userData.passwordUpdate) {
      return <ChangePasswordForm onSubmit={onSubmit} reRef={reRef} />;
    } else {
      return (
        <>
          <Sider hasAccess={hasAccess} />
          <Layout
            style={{
              padding: biggerThan900 ? "0 24px 24px" : "0px",
              marginTop: userData?.type === "CLIENT" ? "70px" : "15px",
            }}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <Text
                  style={{
                    fontSize: "24px",
                    marginTop: "15px",
                  }}
                  strong
                >
                  {title}
                </Text>
              </Col>
              <Row>
                {biggerThan900 && (
                  <Col>
                    {boton && (
                      <Button
                        icon={
                          loading ? (
                            <Spin
                              style={{ color: "white" }}
                              indicator={antIcon}
                            />
                          ) : (
                            <DownloadOutlined />
                          )
                        }
                        onClick={
                          window.location.pathname === "/vehiculos"
                            ? downloadVehiclesTemplate
                            : downloadTemplate
                        }
                        className="gama-outline"
                        style={{ width: "100%" }}
                      >
                        Descargar Reportes
                      </Button>
                    )}
                    {agendamiento && userData.type === "CLIENT" && (
                      <Button className="gama-button" style={{ width: "100%" }}>
                        <a href={BASE_URL}>Ir a agendamiento</a>
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
              {!biggerThan900 && (
                <Col span={24}>
                  {boton && (
                    <Button
                      icon={<DownloadOutlined />}
                      className="gama-outline"
                      onClick={
                        window.location.pathname === "/vehiculos"
                          ? downloadVehiclesTemplate
                          : downloadTemplate
                      }
                      style={{
                        width: "100%",
                        marginTop: biggerThan900 ? " " : "16px",
                      }}
                    >
                      Descargar Reportes
                    </Button>
                  )}
                  {agendamiento && userData.type === "CLIENT" && (
                    <Button
                      className="gama-button"
                      style={{
                        width: "100%",
                        marginTop: biggerThan900 ? " " : "16px",
                      }}
                    >
                      <a href={BASE_URL}>Ir a agendamiento</a>
                    </Button>
                  )}
                </Col>
              )}
            </Row>

            <Content
              className={noContent ? "" : "site-layout-background"}
              style={{
                padding: tabs
                  ? 0
                  : window.location.pathname === "/perfil"
                  ? "0px 0px 0px 0px"
                  : "10px 24px 24px 24px",

                marginTop: noContent ? "" : "1.5rem",
                paddingLeft: biggerThan900 ? "24px" : "0px",
                minHeight: 280,
                borderTopLeftRadius: tabs ? 0 : 15,
                backgroundImage: backgroundImg ? `url(${backgroundImg})` : "",
                display: backgroundImg ? "flex" : "",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                justifyContent: backgroundImg ? "center" : "",
                alignItems: backgroundImg ? "center" : "",
              }}
            >
              {children}
            </Content>
          </Layout>
        </>
      );
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header hasAccess={hasAccess} user={userData} onSignOut={onSignOut} />
      <Layout className="layout-container">
        {userData?.type === "CLIENT" && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              right: biggerThan900 ? 0 : "",
              margin: biggerThan900 ? "15px 50px 0px 0" : "15px 45px 0px 0",
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              top: 70,
              paddingBottom: "15px",
            }}
          >
            <p
              style={{
                margin: 0,
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              Empresa:
            </p>
            <Dropdown
              trigger={["click"]}
              overlay={menu}
              placement="bottomRight"
            >
              <Button
                style={{
                  width: "328px",
                  textAlign: "left",
                  backgroundColor: "#FFFFFF",
                  color: "#262626",
                  borderRadius: "6px",
                  border: "none",
                }}
              >
                <Row justify="space-between">
                  {userData.defaultCompany
                    ? getCompanyName(
                        userData.defaultCompany.length >= 35
                          ? userData.defaultCompany.length.slice(0, 35) + "..."
                          : userData.defaultCompany.length
                      )
                    : userData.accessToCustomers?.[0]?.name.length >= 35
                    ? userData.accessToCustomers?.[0]?.name?.slice(0, 35) +
                      "..."
                    : userData.accessToCustomers?.[0]?.name}
                  <CaretDownOutlined />
                </Row>
              </Button>
            </Dropdown>
          </div>
        )}

        {renderContent()}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
