import { Col, Divider, Row } from "antd";
import React from "react";
import logoMobility from "../../assets/img/logo-mobility.webp";
import emergency from "../../assets/img/emergencia.png";
import Footer from "../../components/layouts/HomeLayout/Footer";
import { useMediaPredicate } from "react-media-hook";
import icon01 from "../../assets/img/images-left/icon-001.svg";
import icon02 from "../../assets/img/images-left/icon-002.svg";
import icon04 from "../../assets/img/images-left/icon-004.svg";

function Maintenance() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  return (
    <div>
      <Row style={{ backgroundColor: "rgba(45, 45, 45, 1)" }}>
        <Col
          style={{
            paddingTop: "14px",
            paddingLeft: "65px",
            paddingBottom: "14px",
          }}
        >
          <img
            style={{ width: "156px", height: "50px" }}
            alt="mobility-main-logo"
            src={logoMobility}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <img
            alt="maintenance-logo"
            style={{ marginTop: "120px" }}
            src={emergency}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col
          style={{
            width: "40%",
            fontSize: "30px",
            fontWeight: "bold",
            marginTop: "36px",
          }}
        >
          Estimados Clientes,
        </Col>
      </Row>
      <Row justify="center">
        <Col style={{ width: "40%", marginTop: "10px" }}>
          Debido a los inconvenientes de nuestro portal de siniestros,
          agradeceremos seguir las siguientes instrucciones:
        </Col>
      </Row>
      <Row justify="center">
        <Col style={{ width: "37%", marginTop: "10px" }}>
          <ul
            style={{
              listStyleType: "circle",
              lineHeight: "18px",
            }}
          >
            <li style={{ marginBottom: "22px" }}>
              Para declarar siniestros: enviar e-mail a
              <span
                style={{
                  textDecoration: "underline",
                  color: "rgba(255, 95, 0, 1)",
                  marginLeft: "5px",
                }}
              >
                siniestros@gamaleasing.cl
              </span>{" "}
              indicando la patente en el asunto, para recibir instrucciones.
            </li>
            <li>
              En caso de Robo Total del vehículo, comunicarse al Celular:
              +56996723943 y enviar un e-mail de respaldo a
              <span
                style={{
                  textDecoration: "underline",
                  color: "rgba(255, 95, 0, 1)",
                  marginLeft: "5px",
                }}
              >
                siniestros@gamaleasing.cl
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row justify="center">
        <Col style={{ marginTop: "20px", marginBottom: "120px" }}>
          Estamos trabajando para una pronta solucion.
        </Col>
      </Row>

      <Row
        align="middle"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alingItems: "center",
          marginTop: "10px",
          marginBottom: "70px",
        }}
      >
        <Row
          align="middle"
          style={{
            display: "flex",
            flexDirection: "column",
            alingItems: "center",
            marginBottom: biggerThan900 ? "0px" : "30px",
          }}
        >
          <Col style={{ minHeight: "56px" }}>
            <img alt="assistence-24-7" src={icon01} />
          </Col>
          <Col
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "rgba(255, 95, 0, 1)",
              fontWeight: "600",
            }}
          >
            Grúa y Asistencia en Ruta 24/7
          </Col>
          <Col>+56 2 3278 5997</Col>
        </Row>
        {biggerThan900 ? (
          <Divider style={{ height: "150px" }} type="vertical" />
        ) : null}
        <Row
          align="middle"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: biggerThan900 ? "0px" : "30px",
          }}
        >
          <Col style={{ minHeight: "56px" }}>
            <img alt="24-7-emergency-channel" src={icon02} />
          </Col>
          <Col
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "rgba(255, 95, 0, 1)",
              width: "250px",
              fontWeight: "600",
            }}
          >
            Emergencias Gama 24/7 Aviso de Robo Vehículo aquí
          </Col>
          <Col>+56 44 222 9975</Col>
        </Row>
        {biggerThan900 ? (
          <Divider style={{ height: "150px" }} type="vertical" />
        ) : null}
        <Row
          align="middle"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: biggerThan900 ? "0px" : "30px",
          }}
        >
          <Col style={{ minHeight: "56px" }}>
            <img alt="Doubts-Declaration-of-Loss" src={icon04} />
          </Col>
          <Col
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "rgba(255, 95, 0, 1)",
              fontWeight: "600",
            }}
          >
            Dudas Declaración de Siniestro
          </Col>
          <Col>Siniestros@gamaleasing.cl</Col>
        </Row>
      </Row>
      <Footer />
    </div>
  );
}

export default Maintenance;
