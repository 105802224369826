import React, { useState } from 'react'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button as LinkButton, Button as DashedButton, Drawer, Form, message, Row, Upload } from 'antd';
import Button from '../../../components/Button';
import './index.less';
import { bulkUsersDataDownloadRequest, bulkUsersDataUploadRequest } from '../../../requests/users';


const BulkUploadUsersForm = ({
  open,
  onClose,
  onFormFinish
}) => {
  const [file, setFile] = useState();

  const bulkTemplateDownload = async () => {

    const { data } = await bulkUsersDataDownloadRequest('insert');

    const fileURL = window.URL.createObjectURL(data);

    let fileLink = document.createElement('a');
    fileLink.href = fileURL;

    fileLink.download = 'insertar-usuarios';

    fileLink.click();
  }

  const closeActions = () => {
    onClose();
    setFile(null);
  }

  const onSubmit = async () => {
    try {
      const response = await bulkUsersDataUploadRequest({ file }, 'insert');

      message.success({
        content: response.data.message,
        duration: 5,
      });

      closeActions();
      onFormFinish();
      setFile(null);

      return false;

    } catch (error) {
      if (error?.status === 413) return message.error({
        content: 'El archivo que itentas subir excede el tamaño máximo de un 1MB',
        duration: 5,
      })

      if (error?.data) {
        return message.error({
          content: error?.data?.message || 'Ups algo salio mal, vuelve a intetarlo más tarde',
          duration: 5,
        });
      } else if (error?.request) {

        return message.error({
          content: 'Lo sentimos ha ocurrido un error',
          duration: 5,
        });
      } else {

        return message.error({
          content: 'Ups algo salio mal, vuelve a intetarlo más tarde',
          duration: 5,
        })
      }


    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setFile(null)
      return e;
    }
    
    if (e.fileList[0])
      setFile(e.fileList[0].originFileObj);
    else
      setFile(null);

    return e?.fileList;
  };

  return (
    <Drawer
      title="Carga masiva de usuarios"
      placement="right"
      onClose={closeActions}
      visible={open}
      className="container-bulk-drawer"
      destroyOnClose={true}
      width={480}
    >
      <Form>
        <Form.Item>
          <p>Aquí podrás cargar varios usuarios a la vez.</p>
        </Form.Item>


        <Form.Item>
          <h5>Cargar usuarios</h5>
          <p>Descarga la plantilla, llénala con los datos requeridos y sube el archivo.</p>
          <LinkButton
            type="link"
            icon={<DownloadOutlined />}
            onClick={() => bulkTemplateDownload()}
          >Descargar plantilla</LinkButton>
        </Form.Item>

        <Form.Item
          name={'upload'}
          valuePropName="list"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Es necesario cargar un archivo csv',
            },
          ]}
        >
          <Upload
            accept=".csv"
            showUploadList={true}
            beforeUpload={() => false}
            maxCount={1}
            listType="csv-file"
            style={{
              display: 'block',
              width: '100%'
            }}
          >
            <DashedButton
              type="dashed"
              style={{
                borderColor: '#FF3200',
                color: '#FF3200',
                marginRight: '1rem',
                width: '100%'
              }}
              size="large"
              icon={<UploadOutlined />}
            >Subir archivo CSV</DashedButton>
          </Upload>
        </Form.Item>

        <Row justify='center'>
          <Button
            type="primary"
            style={{
              width: '60%',
              marginTop: '1rem'
            }}
            onClick={() => onSubmit()}
          >Guardar</Button>
        </Row>


      </Form>
    </Drawer >
  )
}

export default BulkUploadUsersForm;