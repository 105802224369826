import request from "../request";

import { BASE_USER_URL, BASE_USERS_API_URL, BASE_AUTH_API_URL } from "../url";

export const getGamaRequest = async (
  filters,
  query,
  type = "GAMA",
  search = "",
  orderBy = "DESC",
  orderByProperty = "createdAt"
) => {
  const queryParams = new URLSearchParams(query).toString();
  return await request({
    url:
      `${BASE_USER_URL}?type=${type}&page=${
        filters?.currentPage || 1
      }&pageSize=${9999}&orderBy=${orderBy}&orderByProperty=${orderByProperty}&` +
      queryParams,
    method: "GET",
  });
};

export const getUsersGamaRequest = async (query, filters, type = "GAMA") => {
  let {
    orderByProperty = "createdAt",
    orderBy = "DESC",
    currentPage = 1,
    pageSize = 7,
  } = query;

  var search = "";
  var role = "";
  let customer = "";
  if (filters && filters.search) {
    search = typeof filters.search !== "undefined" ? filters.search : "";
  }

  if (filters && filters.role) {
    role = typeof filters.role !== "undefined" ? filters.role : "";
  }

  if (filters && filters.customer) {
    customer = typeof filters.customer !== "undefined" ? filters.customer : "";
  }

  if (!orderBy) {
    orderBy = "DESC";
  }

  const params = {
    type,
    orderBy,
    orderByProperty,
    page: currentPage,
    pageSize,
    search,
    role,
    customer,
  };

  const queryParams = new URLSearchParams(params);
  return await request({
    url: BASE_USER_URL + "?" + queryParams,
    method: "GET",
  });
};

export const getUserRequest = async (userId) => {
  return await request({
    url: `${BASE_USER_URL}/users/${userId}`,
    method: "GET",
  });
};

export const findUserByRut = async (userRut) => {
  return await request({
    url: `${BASE_USER_URL}/find/rut/${userRut}`,
    method: "GET",
  });
};
export const getUserCount = async () => {
  return await request({
    url: `${BASE_USER_URL}/users/count`,
    method: "GET",
  });
};

export const findUserByEmail = async (userEmail) => {
  return await request({
    url: `${BASE_USER_URL}/find/email/${userEmail}`,
    method: "GET",
  });
};

export const addUserToCompanyRequest = async (user) => {
  return await request({
    url: `${BASE_USER_URL}`,
    method: "POST",
    data: {
      ...user,
    },
  });
};

export const getAllUserCust = async () => {
  return await request({
    url: `${BASE_USER_URL}/customers/assignment/list`,
    method: "GET",
  });
};

export const updateUserRequest = async ({ userId, data }) => {
  return await request({
    url: `${BASE_USER_URL}/users/${userId}`,
    method: "PATCH",
    data: {
      ...data,
    },
  });
};

export const removeAccess = async (accessId) => {
  return await request({
    url: `${BASE_USER_URL}/accesses/${accessId}`,
    method: "DELETE",
  });
};

export const passwordRecovery = async (data) => {
  return await request({
    url: `${BASE_AUTH_API_URL}/auth/recovery`,
    method: "POST",
    data,
  });
};

export const passwordChange = async ({ userId, data }) => {
  return await request({
    url: `${BASE_USER_URL}/reset/${userId}`,
    method: "PATCH",
    data: data,
  });
};

export const updateDefaultCompany = async (customerId) => {
  return await request({
    url: `${BASE_USER_URL}/accesses/${customerId}`,
    method: "PUT",
  });
};

export const bulkUsersDataDownloadRequest = async (action) => {
  return await request({
    url: `${BASE_USER_URL}/upload/${action}`,
    method: "GET",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
    },
    responseType: "blob",
  });
};

export const bulkUsersDataUploadRequest = async (data, action) => {
  return await request({
    url: `${BASE_USER_URL}/upload/${action}`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      ...data,
    },
  });
};

export const deleteUser = async (id) => {
  return await request({
    url: `${BASE_USER_URL}/${id}`,
    method: "DELETE",
  });
};

export const exportTemplate = async () => {
  return await request({
    url: `${BASE_USERS_API_URL}/requests/export`,
    method: "GET",
    responseType: "arraybuffer",
  });
};
