import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken, signOut } from "../store/auth";
import { Navigate } from "react-router-dom";
import { refreshAuth } from "../store/auth/thunks";
import { Modal } from "antd";
import { setToken } from "../utils/jwt";

export const ProtectedRoute = ({ children }) => {
  const searchParams = new URLSearchParams(document.location.search);

  if (searchParams.get('msaccess')) setToken(searchParams.get('msaccess'));

  const dispatch = useDispatch();
  let token = useSelector(getAccessToken);
  let timeout = null;
  let tokenTime = null;

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      clearTimeout(tokenTime);

      dispatch(signOut());
      Modal.info({
        content: "sesión expirada",
      });
      return <Navigate to="/" />;
    }, 1000 * 60 * 30); // 60 Seconds // 30 minutes
  }

  const parseJwt = (token) => {
    try {
      return JSON.parse(window.atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }

  const decodedJwt = parseJwt(token);

  const refreshToken = () => {
    if (tokenTime) {
      clearTimeout(tokenTime);
    }
    tokenTime = setTimeout(() => {
      dispatch(refreshAuth(token));
    }, ((decodedJwt.exp - decodedJwt.iat) * 1000) - 900000)
  }

  useEffect(() => {
    if (token) {
      refreshToken();
    }
    restartAutoReset();

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      if (timeout || tokenTime) {
        clearTimeout(timeout);
        clearTimeout(tokenTime);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [token, dispatch, getAccessToken]);


  const onMouseMove = () => {
    restartAutoReset();
  }

  if (!token) {
    return <Navigate to="/" />;
  }

  if (decodedJwt.exp * 1000 < Date.now()) {
    dispatch(signOut());
    Modal.info({
      content: "sesión expirada",
    });
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
