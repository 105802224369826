import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  attachAccessRequest,
  authUserRequest,
  getUserProfileRequest,
  refreshTokenAuth,
  setClientHash,
} from "../../requests/auth";
import { getPermissionsRequest, getRoleRequest } from "../../requests/roles";
import { getUserRequest } from "../../requests/users";
import { getProfilePicture } from "../../requests/profile";
import { getToken, getTokenPayload, isExpiredToken, setToken } from "../../utils/jwt";
import Notifications from "../../components/Notifications";

export const authUser = createAsyncThunk(
  `auth/authUser`,
  async ({ rut, password, remember, reCaptcha }, { rejectWithValue }) => {
    try {
      const cleanRut = rut.replace(/\./g, "");
      const { data } = await authUserRequest({ rut: cleanRut, password, reCaptcha });
      console.log(data.error, "data error")
      if (data.error && data.error === "INVALID_CREDENTIALS") throw new Error("Error en el Rut o la Contraseña.");

      const { accessToken } = data;

      if (!accessToken || accessToken === undefined) throw new Error("INVALID CREDENTIALS");

      setToken(accessToken);
      const { userId } = getTokenPayload(accessToken);
      const { data: userData } = await getUserRequest(userId);
      const { data: access } = await attachAccessRequest({ customerId: userData.accesses[0].customer.id });
      setToken(access.accessToken);

      if (remember) {
        const { data } = await setClientHash({ rut, password });

        localStorage.setItem('local.pass', data);
        localStorage.setItem('local.usuario', rut);
      } else {
        localStorage.removeItem('local.pass');
        localStorage.removeItem('local.usuario');
      }


      return {
        accessToken: accessToken,
      };
    } catch (error) {
      if(error.status === 403){
        Notifications.error("Error", "Problema en inicio de sesión, por favor contactar a un ejecutivo.");
      } else {
        message.error({
          content: "Error en el Rut o la Contraseña.",
          duration: 5,
        });
      }
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getAuthProfile = createAsyncThunk(
  "auth/getAuthProfile",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUserProfileRequest();
      const { data: user } = await getUserRequest(data.userData?.id);
      const { data: role } = await getRoleRequest(user?.accesses[0].role.id);
      const { data: profileImageUrl } = await getProfilePicture();
      return {
        ...data.userData,
        profileImageUrl,
        permissions: role.permissions
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const attachAccess = createAsyncThunk(
  "auth/attachAccess",
  async (customerId, { rejectWithValue }) => {
    try {
      const token = getToken();
      if (isExpiredToken(token)) return { accessToken: token };

      const { data } = await attachAccessRequest({ customerId });
      return { ...data };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const refreshAuth = createAsyncThunk(
  "auth/refreshAuth",
  async ({ rejectWithValue }) => {
    try {
      const { data } = await refreshTokenAuth()
      return data;
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getPermissions = createAsyncThunk(
  "auth/getPermissions",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getPermissionsRequest();
      return data;
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
)