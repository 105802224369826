import React, { useEffect, useState } from "react";
import {
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Button as AntdButton,
  Col,
  Spin,
  Upload,
  Modal,
} from "antd";
import { format, validate } from "rut.js";
import {
  CaretDownOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  addUserToCompanyRequest,
  findUserByEmail,
  findUserByRut,
  updateUserRequest,
} from "../../../requests/users";
import Notifications from "../../../components/Notifications";
import Button from "../../../components/Button";
import IconButton from "../../../components/IconButton";
import Tooltip from "../../../components/Tooltip";
import "./index.less";
import { useMediaPredicate } from "react-media-hook";
import defaultLogo from "../../../assets/img/Default_pfp.webp";
import { getUserData } from "../../../store/auth";
import { useSelector } from "react-redux";
import {
  getProfilePicture,
  updateProfilePicture,
} from "../../../requests/profile";
import { isSuperAdmin } from "../../../utils/roles";
import { getCustomerAssignmentRequest } from "../../../requests/customers";

const { Option } = Select;

var stopClickEvent = 1;

const UserForm = ({
  onClose,
  open,
  customers = [],
  userToUpdate,
  onFormFinish,
  tab,
}) => {
  const [form] = Form.useForm();
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [password, setPassword] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [toolTipMessage, setToolTipMessage] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const { accesses } = useSelector(getUserData);
  const userData = useSelector(getUserData);
  const [customersData, setCustomersData] = useState([]);
  let interval;
  const onSelectCustomer = ({ key, value }) => {
    setSelectedCustomers((prevState) => {
      const isItemExist = prevState.some((item) => item.key === key);

      if (isItemExist) {
        return prevState.map((item) => {
          if (item.key === key) {
            return { key, value };
          }

          return item;
        });
      }

      return [...prevState, { key, value }];
    });
    const accesses = form.getFieldValue("accesses");
    if (accesses) {
      form.setFieldValue(
        "accesses",
        accesses?.map((access) => {
          if (access?.customerId === value) {
            return {
              ...access,
              roleId: null,
            };
          }
          return access;
        })
      );
    }
  };

  const cleanData = () => {
    form.resetFields();
    setSelectedCustomers([]);
  };

  const passwordGenerator = () => {
    const array = new Uint32Array(1);
    setPassword(window.crypto.getRandomValues(array).toString());
  };

  const getProfileData = () => {
    const getProfileImage = async (userId) => {
      const { data } = await getProfilePicture(userId);
      setProfileImage(data);
    };

    if (userToUpdate) getProfileImage(userToUpdate.id);
  };

  useEffect(() => {
    getProfileData();
  }, [userToUpdate]);

  useEffect(() => {
    if (userToUpdate) {
      userToUpdate.accesses.map((access, index) =>
        onSelectCustomer({ key: index, value: access.customer.id })
      );
      form.setFieldsValue({
        ...userToUpdate,
        accesses: userToUpdate.accesses.map((access) => ({
          customerId: access.customer.id,
          roleId: access.role.id,
        })),
      });
    } else {
      cleanData();
      passwordGenerator();
    }
  }, [form, userToUpdate]);

  useEffect(() => {
    form.setFieldsValue({
      password: password,
      phonePrefix: "56",
    });
  }, [password, passwordGenerator]);

  const onCloseModal = () => {
    cleanData();
    onClose();
  };
  const cleanRut = (text) => text.replace(/\./g, "");

  const onCreateUser = async (values) => {
    await addUserToCompanyRequest({
      ...values,
      rut: cleanRut(values.rut),
    });
  };

  useEffect(async () => {
    try {
      const { data } = await getCustomerAssignmentRequest({
        pageSize: 9999,
      });
      setCustomersData(data);
    } catch (error) {
      console.error(error);
    }
  }, [userToUpdate]);

  const onUpdateUser = async ({ rut, ...values }) => {
    values.phoneNumber = values.phoneNumber.toString();
    await updateUserRequest({ userId: userToUpdate.id, data: values });
  };
  const onSubmit = async (values) => {
    try {
      if (userToUpdate) {
        await onUpdateUser(values);
      } else {
        await onCreateUser(values);
      }

      Notifications.success(
        "Listo",
        userToUpdate ? "Usuario actualizado" : "Usuario creado"
      );

      onFormFinish();
      onCloseModal();
    } catch (error) {
      if (error?.status === 401) {
        Notifications.error(
          "Error",
          "No tienes los permisos para realizar esa accion."
        );
      } else if (error.status === 409) {
        Notifications.error(
          "Error",
          "El correo que intentas ingresar ya existe."
        );
      } else {
        Notifications.error(
          "Error",
          "Ha ocurrido un error al añadir el usuario"
        );
      }
    }
  };

  const fileTypes = ["image/jpeg", "image/png"];

  const handleChange = async (info) => {
    try {
      if (info.fileList[0].originFileObj > 1) {
        Notifications.error(
          "Error al cargar",
          "No se puede subir mas de una imagen."
        );
      } else if (!fileTypes.includes(info?.fileList[0]?.originFileObj?.type)) {
        return Modal.error({
          content: (
            <>
              <div>Archivo no valido</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, png.</span>
            </>
          ),
        });
      } else if (info?.fileList[0]?.originFileObj?.size === 15000000) {
        Notifications.error(
          "Error al cargar",
          "El peso del archivo supera el máximo de 15MB."
        );
      } else {
        if (stopClickEvent === 3) {
          await updateProfilePicture(
            info.fileList[0].originFileObj,
            userToUpdate?.id
          );
          Notifications.success(
            "Cambio de imagen",
            "Se ha actualizado su imagen de perfil satisfactoriamente."
          );
          stopClickEvent = 1;
          getProfileData();
        }
      }
    } catch (error) {
      console.error(error);
      Notifications.error(
        "Error al cargar",
        "Ocurrio un error al cargar la imagen."
      );
    }
  };
  const filteredCustomers =
    tab === "client"
      ? customersData?.customers &&
        customersData?.customers?.filter(
          (customer) => customer.name !== "GAMA LEASING OPERATIVO SPA"
        )
      : customersData?.customers && customersData?.customers;

  const formCustomers = isSuperAdmin(accesses)
    ? customers.customers
      ? customers.customers
      : customers
    : filteredCustomers;

  return (
    <Drawer
      title={
        <div>
          <Row justify="space-between" align="middle">
            <Col>{userToUpdate ? "Editar usuario" : "Crear nuevo usuario"}</Col>
            <Col>
              <Button
                onClick={onClose}
                style={{
                  border: "none",
                  padding: "2px 8px",
                  height: "30px",
                  color: "#FF3200",
                  borderRadius: "6px",
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                }}
              >
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      }
      placement="right"
      onClose={onCloseModal}
      visible={open}
      destroyOnClose
      closable={false}
      width={biggerThan900 ? 480 : "100%"}
    >
      <Form
        name="create-user"
        form={form}
        initialValues={{ accesses: [""] }}
        layout="vertical"
        onFinish={onSubmit}
      >
        <div
          style={{
            position: "relative",
            marginBottom: "30px",
            display: "inline-block",
            width: "auto",
          }}
        >
          {isSuperAdmin(accesses) ||
          userData?.accesses[0]?.role.code === "Administrador" ? (
            <Upload
              showUploadList={false}
              onChange={(info) => {
                if (stopClickEvent === 3) {
                  handleChange(info);
                  stopClickEvent = 1;
                } else {
                  stopClickEvent = stopClickEvent + 1;
                }
              }}
              accept=".png,.jpg,.jpeg,.webp"
            >
              <img
                className="profile_image"
                style={{ height: "80px", width: "80px", borderRadius: "50px" }}
                src={userToUpdate ? profileImage : defaultLogo}
              />
              <div
                className="container-plus"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  background: "#FFFF",
                  display: "table",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <PlusCircleFilled
                  style={{
                    color: "#FF3200",
                    height: "1.2rem",
                    display: "table-row",
                    fontSize: "1.2rem",
                  }}
                />
              </div>
            </Upload>
          ) : (
            <>
              <img
                className="profile_image"
                style={{ height: "80px", width: "80px", borderRadius: "50px" }}
                src={userToUpdate ? profileImage : defaultLogo}
              />
            </>
          )}
        </div>
        <Form.Item
          name="rut"
          style={{ width: 225 }}
          rules={[
            { required: true, message: "Ingrese el rut." },
            {
              validator: async (_, value) => {
                if (!validate(value))
                  return Promise.reject("El rut ingresado es inválido.");
                if (userToUpdate) return;

                const { data } = await findUserByRut(cleanRut(value));
                if (data) return Promise.reject("El rut ingresado ya existe.");
              },
            },
          ]}
          normalize={(value) => format(value)}
        >
          <Input disabled={!!userToUpdate} placeholder="RUT" />
        </Form.Item>
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el nombre",
            },
            {
              whitespace: true,
            },
            {
              min: 3,
              message: "Debe tener al menos 3 caracteres",
            },
            {
              max: 50,
              message: "Debe tener máximo 50 caracteres",
            },
            {
              //Mayusculas, minusculas, acentos, espacio, apostrofe, guines
              pattern: /^[A-Za-z\'\s\.\,Ññ]+$/,
              message: "El nombre solo debe incluir letras.",
            },
          ]}
        >
          <Input placeholder="Nombre" />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el apellido",
            },
            {
              whitespace: true,
            },
            {
              min: 2,
              message: "Debe tener al menos 2 caracteres",
            },
            {
              max: 50,
              message: "Debe tener máximo 50 caracteres",
            },
            {
              //Mayusculas, minusculas, acentos, espacio, apostrofe
              pattern: /^[A-Za-z\'\s\.\,Ññ]+$/,
              message: "El apellido solo debe incluir letras.",
            },
          ]}
        >
          <Input placeholder="Apellido" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "Por favor ingrese un correo válido",
            },
            {
              required: true,
              message: "Por favor ingrese el correo",
            },
            {
              max: 100,
              message: "No puede ingresar más de 100 caracteres en un correo",
            },
            {
              whitespace: true,
            },
            {
              validator: async (_, value) => {
                if (userToUpdate) return;
                if (value.length === 0) return;
                if (interval) window.clearInterval(interval);
                if (value.includes("/")) return;
                try {
                  const { data } = await findUserByEmail(value);
                  if (data) {
                    return Promise.reject("El correo ingresado ya existe.");
                  } else {
                    Promise.resolve();
                  }
                } catch (error) {
                  console.error(error);
                }
              },
            },
          ]}
        >
          <Input placeholder="Correo electrónico" />
        </Form.Item>

        <Input.Group compact>
          <Form.Item
            name="phonePrefix"
            rules={[
              { required: true, message: "Por favor ingresa el prefijo" },
            ]}
          >
            <Select suffixIcon={<CaretDownOutlined />} style={{ width: 100 }}>
              <Option value={"56"}>+56</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el número de telefono",
              },
              {
                //Numeros, signo +
                pattern: /^[+]*\d{8,9}$/,
                message:
                  "Ingrese un número válido, sin letras y entre 8 y 9 dígitos.",
              },
            ]}
          >
            <Input placeholder="Teléfono" />
          </Form.Item>
        </Input.Group>
        <span style={{ fontWeight: "bold", margin: 5 }}>
          Asignar perfiles y empresas
        </span>
        <Form.List
          rules={[
            {
              required: true,
              message: "Por favor selecciona una Empresa",
            },
          ]}
          name="accesses"
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <>
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                      flexDirection: "column",
                    }}
                    align="baseline"
                  >
                    <div style={{ display: "inline-flex" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "customerId"]}
                        style={{
                          marginRight: 5,
                          width: biggerThan900 ? 400 : 250,
                          height: 10,
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Por favor selecciona una Empresa",
                          },
                        ]}
                      >
                        <Select
                          suffixIcon={<CaretDownOutlined />}
                          notFoundContent={
                            !customers ? <Spin size="small" /> : null
                          }
                          showSearch
                          onChange={(value) =>
                            onSelectCustomer({ key: key, value: value })
                          }
                          style={{
                            width: "100%",
                          }}
                          placeholder="Empresa"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label.toLowerCase() ?? "").includes(
                              input.toLowerCase()
                            )
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={
                            formCustomers &&
                            formCustomers
                              ?.filter(
                                (customer) =>
                                  !selectedCustomers.some(
                                    (currCustomer) =>
                                      customer.id === currCustomer.value &&
                                      key !== currCustomer.key
                                  )
                              )
                              .map((customer) => {
                                return {
                                  label: customer.name,
                                  value: customer.id,
                                };
                              })
                          }
                        />
                      </Form.Item>
                      {index > 0 && (
                        <DeleteOutlined
                          style={{
                            marginTop: 10,
                            color: "#FF3200",
                            backgroundColor: "rgba(255, 50, 0, 0.06)",
                            padding: "5px 5px",
                            borderRadius: "6px",
                          }}
                          onClick={() => remove(name)}
                        />
                      )}
                    </div>
                    {selectedCustomers.find((item) => item.key === key) && (
                      <Form.Item
                        {...restField}
                        name={[name, "roleId"]}
                        style={{
                          marginRight: 5,
                          width: biggerThan900 ? 400 : 250,
                          height: 10,
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Por favor selecciona un perfil",
                          },
                        ]}
                      >
                        <Select
                          suffixIcon={<CaretDownOutlined />}
                          placeholder="Perfil"
                        >
                          {formCustomers &&
                            formCustomers
                              ?.filter((customer) => {
                                const selectedCustomer = selectedCustomers.find(
                                  (item) => item.key === key
                                );
                                return customer.id === selectedCustomer.value;
                              })
                              .map((customer) => {
                                return customer?.roles
                                  ?.filter((rol) => rol.enabled === true)
                                  .map((role) => (
                                    <Option key={role.code} value={role.id}>
                                      {role.code}
                                    </Option>
                                  ));
                              })}
                        </Select>
                      </Form.Item>
                    )}
                  </Space>
                </>
              ))}
              <Divider style={{ marginTop: "50px" }} />
              <Form.Item>
                <div
                  style={{ cursor: "pointer", color: "#FF5C00" }}
                  onClick={() => add()}
                >
                  <PlusCircleOutlined />
                  <span style={{ marginLeft: 5 }}>Agregar otro</span>
                </div>
              </Form.Item>
            </>
          )}
        </Form.List>
        {!userToUpdate && (
          <Input.Group compact>
            <p className="title-password-generate">Contraseña temporal</p>
            <p className="info-password-generate">
              Esta contraseña tendrá una validez de 72 horas. Por favor copie
              esta contraseña y envíesela al usuario como respaldo.
            </p>
            <Form.Item name="password">
              <Input
                disabled={true}
                style={{
                  color: "#262626",
                }}
              />
            </Form.Item>
            <Tooltip
              onChangeMessage={toolTipMessage}
              defaultMessage="Copiar el password"
              actionMessage="¡ copiado !"
            >
              <AntdButton
                icon={<CopyOutlined />}
                onClick={() => {
                  setToolTipMessage(true);
                  navigator.clipboard.writeText(password);
                  setTimeout(() => setToolTipMessage(false), 1000);
                }}
              />
            </Tooltip>

            <div
              style={{
                marginLeft: ".5rem",
              }}
            >
              <IconButton
                icon={<ReloadOutlined />}
                onClick={() => passwordGenerator()}
                defaultMessage="Generar un nuevo password"
                actionMessage="¡ password generado !"
              />
            </div>
          </Input.Group>
        )}
      </Form>

      <Row style={{ marginTop: 30 }}>
        <Button
          className="gama-outline"
          onClick={() => onCloseModal()}
          style={{ width: "40%", margin: 5 }}
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          style={{ width: "50%", margin: 5 }}
          onClick={() => form.submit()}
        >
          {userToUpdate ? "Editar usuario" : "Crear nuevo usuario"}
        </Button>
      </Row>
    </Drawer>
  );
};
export default UserForm;
