import React from "react";
import AppLayout from "../../components/layouts/AppLayout";
import sinisterBg from "../../assets/img/sinisterBg.png";
import Button from "../../components/Button";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
function Siniestros() {
  return (
    <AppLayout backgroundImg={sinisterBg} title="Siniestros">
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col>
          <Button>
            <Link to="/maintenance">Ir al portal de siniestros</Link>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  );
}

export default Siniestros;
